import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router for navigation
import { checkavAilability } from '../../crud/book.crud';

const HomeBooking = () => {
    const navigate = useNavigate();
    const [searchData, setSearchData] = useState({
        bookingFrom: '',
        bookingUpto: '',
        hotel_slug: ''
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSearchData(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const validate = () => {
        const newErrors = {};
        if (!searchData.hotel_slug) newErrors.hotel_slug = 'Please select a hotel';
        if (!searchData.bookingFrom) newErrors.bookingFrom = 'Please select a check-in date';
        if (!searchData.bookingUpto) newErrors.bookingUpto = 'Please select a check-out date';
        return newErrors;
    };

    const handleClick = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        setErrors({});

        try {
            const res = await checkavAilability(searchData);
            const isAvailable = res.data.available;
            const availableRooms = res.data.availableRooms;
            if (isAvailable) {
                navigate('/booking/865235321578', { state: { availableRooms, searchData } });
            } else {
                alert('Room not available. Please try another date.');
            }
        } catch (error) {
            console.error('Error checking availability:', error);
            alert('An error occurred while checking availability. Please try again later.');
        }
    };

    return (
        <div className="container-fluid booking pb-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="container">
                <div className="bg-white shadow" style={{ padding: 35 }}>
                    <div className="row g-2">
                        <div className="col-md-10">
                            <div className="row g-2">
                                <div className="col-md-4">
                                    <div className="form-floating">
                                    <select className={`form-select ${errors.hotel_slug ? 'is-invalid' : ''}`} name="hotel_slug" value={searchData.hotel_slug} onChange={handleChange}>
                                        <option value="">-- select --</option>
                                        <option value="the-odyssey-inn">The Odyssey inn</option>
                                        <option value="the-greenstar-inn">The Greenstar inn</option>
                                    </select>
                                    <label htmlFor="bookingFrom">Our Hotels</label>

                                    {/* {errors.hotel_slug && <div className="text-danger">{errors.hotel_slug}</div>} */}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-floating" id="date1" data-target-input="nearest">
                                        <input
                                            type="date"
                                            className={`form-control ${errors.bookingFrom ? 'is-invalid' : ''}`}
                                            name="bookingFrom"
                                            value={searchData.bookingFrom}
                                            onChange={handleChange}
                                            placeholder="Check in"
                                            
                                        />
                                        <label htmlFor="bookingFrom">Check In</label>
                                    </div>
                                    {/* {errors.bookingFrom && <div className="text-danger">{errors.bookingFrom}</div>} */}
                                </div>
                                <div className="col-md-4">
                                    <div className="form-floating" id="date2" data-target-input="nearest">
                                        <input
                                            type="date"
                                            className={`form-control ${errors.bookingUpto ? 'is-invalid' : ''}`}
                                            name="bookingUpto"
                                            value={searchData.bookingUpto}
                                            onChange={handleChange}
                                            placeholder="Check out"
                                            data-target="#date2"
                                            data-toggle="datetimepicker"
                                        />
                                        <label htmlFor="bookingFrom">Check Out</label>

                                    </div>
                                    {/* {errors.bookingUpto && <div className="text-danger">{errors.bookingUpto}</div>} */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 ">
                            <button className="btn btn-primary w-100  py-3" onClick={handleClick}>Search</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeBooking;
