import axios from './axios.interceptor';
import api from './api.util';

export const HOST = `${api}/`
export const CheckOutUrl = `${HOST}checkout/` 
export const callback_url =`${HOST}paymentVerification/`




export async function getKey( obj ) {
  const url = `https://hotel-greenstar-api-b090b1900d98.herokuapp.com/api/v1/getkey`
  const asd =await axios.get(url, obj, {
    cors: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type"
    }
  });
  return asd;
}
export async function singlePaymentDetails( id ) {
  const url = `${HOST}payment-data/${id}`
  const asd =await axios.get(url, {
    cors: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type"
    }
  });
  return asd;
}
export async function generateInvoice( id ) {
  const url = `${HOST}generate-invoice/${id}`
  const asd =await axios.get(url, {
    cors: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type"
    }
  });
  return asd;
}

export async function checkOut( obj ) {
    const asd =await axios.post(CheckOutUrl, obj, {
      cors: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type"
      }
    });
    return asd;
}

export async function paymentVerification( obj ) {
  const asd =await axios.post(callback_url, obj, {
    cors: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type"
    }
  });
  return asd;
}
