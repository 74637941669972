import React from "react";


import Hotel from "../../component/hotel";

export default function Hotels(){
return(
    <>
    <Hotel />
    
    </>
)
}