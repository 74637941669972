import React, { useState } from 'react';

const Step3 = ({ onNext, onBack, initialData }) => {
    const [userInfo, setUserInfo] = useState({
        clientName: initialData.clientName|| '',
        clientEmail:initialData.clientEmail|| '',
        clientPhone:initialData.clientEmail|| '',
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const validate = () => {
        const newErrors = {};
        if (!userInfo.clientName) {
            newErrors.clientName = 'Name is required';
        }
        if (!userInfo.clientEmail) {
            newErrors.clientEmail = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(userInfo.clientEmail)) {
            newErrors.clientEmail = 'Email is invalid';
        }
        if (!userInfo.clientPhone) {
            newErrors.clientPhone = 'Phone number is required';
        } else if (!/^\d{10}$/.test(userInfo.clientPhone)) {
            newErrors.clientPhone = 'Phone number is invalid';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validate()) return;
        onNext(userInfo);
    };

    return (
        <div className="container my-5">
            <h3 className="my-4 text-primary text-center">Step 3: User Information</h3>
            <div className="user-info-form bg-white p-4 rounded shadow-lg">
                <form onSubmit={handleSubmit}>
                    <div className="row g-3">
                        <div className="col-12">
                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className={`form-control ${errors.clientName ? 'is-invalid' : ''}`}
                                    id="clientName"
                                    name="clientName"
                                    value={userInfo.clientName}
                                    onChange={handleChange}
                                    placeholder="Name"
                                />
                                <label htmlFor="clientName">Name</label>
                                {errors.clientName && <div className="invalid-feedback">{errors.clientName}</div>}
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-floating mb-3">
                                <input
                                    type="email"
                                    className={`form-control ${errors.clientEmail ? 'is-invalid' : ''}`}
                                    id="clientEmail"
                                    name="clientEmail"
                                    value={userInfo.clientEmail}
                                    onChange={handleChange}
                                    placeholder="Email"
                                />
                                <label htmlFor="clientEmail">Email</label>
                                {errors.clientEmail && <div className="invalid-feedback">{errors.clientEmail}</div>}
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-floating mb-3">
                                <input
                                    type="tel"
                                    className={`form-control ${errors.clientPhone ? 'is-invalid' : ''}`}
                                    id="clientPhone"
                                    name="clientPhone"
                                    value={userInfo.clientPhone}
                                    onChange={handleChange}
                                    placeholder="Phone"
                                />
                                <label htmlFor="clientPhone">Phone</label>
                                {errors.clientPhone && <div className="invalid-feedback">{errors.clientPhone}</div>}
                            </div>
                        </div>
                        <div className="col-12">
                            <button className="btn btn-primary w-100 py-3" type="submit">
                                Continue
                            </button>
                            <div onClick={onBack} className="mt-3 text-primary text-center" style={{ cursor: "pointer" }}>
                                <i className="bx bx-left-arrow-alt"></i> Back
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Step3;
