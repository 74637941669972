import React from "react";

import About from "../../component/about/About";

export default function Abouts(){
return(
    <>
    <About />
    </>
)
}