
import React from "react";

export default  function Footer() {
  
  return (
    <>
      {/* ==============  Footer area start================= */}
      <div
        className="container-fluid bg-dark text-light footer wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container pb-5">
          <div className="row g-5">
            <div className="col-md-6 col-lg-4">
              <div className=" rounded p-4">
                <a href="index.html">
                <h1 className="header-title mb-2">
                Odyssey<span className="sub-title">Group of hotels</span>
              </h1>
                </a>
                <p className="text-white mb-0">
                Welcome to The  Odyssey group of Hotels. Step into our elegantly designed rooms, each offering a blend of modern comforts and classic charm. Relax and rejuvenate in our spa, where expert therapists pamper you with a range of indulgent treatments.</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <h6 className="section-title text-start text-primary text-uppercase mb-4">
                Contact
              </h6>
              <p className="mb-2">
              <i class='bx bxs-map'></i>
              Plot No, 223/8 Near Bijupatnaik International Airport, Bhimpur, Unit-6, Bhubneshwar, Odisha 751020
              </p>
              <p className="mb-2">
                <i className="bx bxs-phone " />
                +91-6743858123 
              </p>
              <p className="mb-2">
                <i className="bx bxs-envelope " />
                info@example.com
              </p>
              <div className="d-flex pt-2">
              <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-twitter"></i></a>
                            <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-facebook-f"></i></a>
                            <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-youtube"></i></a>
                            <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-linkedin-in"></i></a>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="row gy-5 g-4">
                <div className="col-md-6">
                  <h6 className="section-title text-start text-primary text-uppercase mb-4">
                    Company
                  </h6>
                  <a className="btn btn-link" href="">
                    About Us
                  </a>
                  <a className="btn btn-link" href="">
                    Contact Us
                  </a>
                  <a className="btn btn-link" href="">
                    Privacy Policy
                  </a>
                  <a className="btn btn-link" href="">
                    Terms &amp; Condition
                  </a>
                  <a className="btn btn-link" href="">
                    Support
                  </a>
                </div>
                <div className="col-md-6">
                  <h6 className="section-title text-start text-primary text-uppercase mb-4">
                    Services
                  </h6>
                  <a className="btn btn-link" href="">
                    Food &amp; Restaurant
                  </a>
                  <a className="btn btn-link" href="">
                    Spa &amp; Fitness
                  </a>
                  <a className="btn btn-link" href="">
                    Sports &amp; Gaming
                  </a>
                  <a className="btn btn-link" href="">
                    Event &amp; Party
                  </a>
                  <a className="btn btn-link" href="">
                    GYM &amp; Yoga
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-4 col-12 text-center text-md-start mb-3 mb-md-0">
                ©{" "}
                <a className="border-bottom" href="#">
                  The Odyssey Inn
                </a>
                , All Right Reserved.
                
                Designed By{" "}
                <a className="border-bottom" href="https://flaamant.com">
                  Flaamant
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

