// utils/mapImagesToRooms.js
import { roomImages } from './importAllImages';

export const mapImagesToRooms = (hotelRooms, hotelSlug) => {
  return hotelRooms.map((room) => {
    const categorySlug = room.roomCategory.replace(/\s+/g, '_');
    const categoryImages = Object.keys(roomImages).filter(img =>
      img.includes(`${hotelSlug}_${categorySlug}`));
    
    const images = categoryImages.map(img => roomImages[img]);

    return {...room,images,};
  });
};
