import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { callback_url, checkOut, getKey, paymentVerification } from '../../../crud/payment.crud'; // Import paymentVerification


const Step4 = ({ userData, onConfirm, onBack }) => {
    const navigate = useNavigate();
    const { bookingFrom, bookingUpto, clientEmail, clientName, clientPhone, selectedRooms } = userData;
    useEffect(() => {
        console.log("selectedRooms:", selectedRooms);
    }, [selectedRooms]);
    const totalAmount = selectedRooms.reduce((sum, room) => sum + (room.price * room.count), 0);
    const allRoomNumbers = [...new Set(selectedRooms.flatMap(room => room.roomNumbers))];
    useEffect(() => {
        console.log("roomNumbers",allRoomNumbers);

    }, [allRoomNumbers]);
    const objData = {
        amount: totalAmount
    };

    const handleConfirm = async () => {
        try {
            const { data: { order } } = await checkOut(objData);
            const { data: { key } } = await getKey();

            const options = {
                key: key, // Enter the Key ID generated from the Dashboard
                amount: order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                currency: "INR",
                name: "greenstar",
                description: "Test Transaction",
                order_id: order.id, // This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                callback_url: callback_url,
                prefill: {
                    name: clientName,
                    email: clientEmail,
                    contact: clientPhone
                },
                notes: {
                    address: "Razorpay Corporate Office"
                },
                theme: {
                    color: "#feaf39"
                },
                handler: async (response) => {
                    const paymentData = {
                        ...response,
                        userData,
                        roomNumbers:allRoomNumbers,
                        order_id: order.id,
                        amount: order.amount
                    };

                    try {
                        const verification = await paymentVerification(paymentData);
                        if (verification.data.success) {
                            console.log("verification.data",verification.data.book.paymentId);
                            alert('Payment Successful');

                            navigate(`/payment-success/${verification.data.book.paymentId}`);
                            // Perform additional actions on success, if needed
                            console.log("verification.data>>:", verification.data);
                        } else {
                            alert('Payment Verification Failed');
                        }
                    } catch (error) {
                        console.error("Error during payment verification:", error);
                    }
                }
            };

            const razor = new window.Razorpay(options);
            razor.open();
        } catch (error) {
            console.error("Error during checkout:", error);
        }
    };

    return (
        <div className="container my-5">
            <h3 className="my-4 text-primary text-center">Step 4: Review Details & Payment</h3>
            <div className="review-details bg-white p-4 rounded shadow-lg">
                <div className="mb-4">
                    <h5 className="text-secondary">Personal Information</h5>
                    <div className="d-flex justify-content-between border-bottom pb-2 mb-3">
                        <p className="mb-0"><strong>Name:</strong> {clientName}</p>
                        <p className="mb-0"><strong>Email:</strong> {clientEmail}</p>
                    </div>
                    <div>
                        <p><strong>Phone:</strong> {clientPhone}</p>
                    </div>
                </div>
                <div className="mb-4">
                    <h5 className="text-secondary">Booking Information</h5>
                    <div className="d-flex justify-content-between border-bottom pb-2 mb-3">
                        <p className="mb-0"><strong>Check-in:</strong> {bookingFrom} at 14:00</p>
                        <p className="mb-0"><strong>Check-out:</strong> {bookingUpto} at 12:00</p>
                    </div>
                </div>
                <div className="mb-4">
                    <h5 className="text-secondary">Selected Rooms</h5>
                    {selectedRooms.map((room, index) => (
                        <div key={index} className="room-detail bg-light border rounded p-3 mb-3">
                            <p className="mb-1"><strong>Room Name:</strong> {room.roomName}</p>
                            <p className="mb-1"><strong>Total Rooms:</strong> {room.count}</p>
                            <p className="mb-1"><strong>Price:</strong> INR {room.price * room.count}</p>
                        </div>
                    ))}
                </div>
                <div className="total-amount mb-4 text-right">
                    <h5>Total Amount: <span className="text-primary">INR {totalAmount}</span></h5>
                </div>
                <button className="btn btn-primary w-100 py-3" onClick={handleConfirm}>
                    Confirm & Proceed to Payment
                </button>
                <div onClick={onBack} className="mt-3 text-primary text-center" style={{ cursor: "pointer" }}>
                    <i className="bx bx-left-arrow-alt"></i> Back
                </div>
            </div>
        </div>
    );
};

export default Step4;
