const Info = () => {
    return (
        <div className="container-fluid py-5 px-0 wow zoomIn" data-wow-delay="0.1s">
            <div className="row g-0">
                <div className="col-md-6 bg-dark d-flex align-items-center">
                    <div className="p-5">
                        <h6 className="section-title text-start text-white text-uppercase mb-3">
                            Luxury Living
                        </h6>
                        <h1 className="text-white mb-4">Your Perfect Stay Starts Here</h1>
                        <p className="text-white mb-4">Welcome to Odyssey Group of hotels, where luxury meets tranquility. Nestled amidst lush greenery, our hotel offers a serene retreat from the hustle and bustle of city life.</p>
                        <p className="text-white mb-4">Step into our elegantly designed rooms, each offering a blend of modern comforts and classic charm. Relax and rejuvenate in our spa, where expert therapists pamper you with a range of indulgent treatments.</p>
                        <p className="text-white mb-4">Whether you're here for business or leisure, our hotels promise a truly unforgettable experience.</p>
                        <a href="" className="btn btn-primary py-md-3 px-md-5 me-3">
                            Our Rooms
                        </a>
                        <a href="" className="btn btn-info py-md-3 px-md-5">
                            Book A Room
                        </a>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="video">
                        {/* <button
                            type="button"
                            className="btn-play"
                            data-bs-toggle="modal"
                            data-src="https://www.youtube.com/embed/DWRcNpR6Kdc"
                            data-bs-target="#videoModal"
                        >
                            <span />
                        </button> */}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Info;