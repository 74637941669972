import axios from './axios.interceptor';
import api from './api.util';

export const Hotel = `${api}`;

export function fetchAllHotel( ) {
    const usrUL = `${Hotel}/hotels`;
    const asd = axios.get(usrUL,{
      cors: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type"
      }
    });
    return asd;
}
export function fetchSingleHotel(slug ) {
  const usrUL = `${Hotel}/hotel/${slug}`;
  const asd = axios.get(usrUL,{
    cors: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type"
    }
  });
  return asd;
}
