import React from "react";
import Contacts from "../../component/contact";



export default function Contact() {
    return (
        <>
            <Contacts />
        </>
    )
}