import React from "react";
import Rooms from "../../component/hotel/room";


export default function Room(){
return(
    <>
    <Rooms />
    </>
)
}