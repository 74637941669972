import React, { useState } from 'react';

const RoomCard = ({ roomName, availableNumber, price,roomNumbers,roomCapacity, onSelect }) => {
  const [counter, setCounter] = useState(0);
  const [selectButtonVisible, setSelectButtonVisible] = useState(true);
  
  const handleIncrement = () => {
    if (counter < availableNumber) {
      setCounter(prevCounter => prevCounter + 1);
    setSelectButtonVisible(true);

    }
  };

  const handleDecrement = () => {
    if (counter > 0) {
      setCounter(prevCounter => prevCounter - 1);
      setSelectButtonVisible(true);
    }
    
  };

  const handleSelect = () => {
    onSelect({ roomName, count: counter,roomNumbers:roomNumbers.slice(0, counter), price });
    setSelectButtonVisible(false);
  };

  return (
    <div className="card my-3">
      <div className="card-body">
        <div className="row">
          <div className="col-md-8">
            <h5 className='avabCardHeading'>{roomName}</h5>
            <p>Available: {availableNumber}</p>
            <p>Price: INR <span className='priceButton'>{price}</span> per night</p>
          </div>
          <div className="col-md-4 text-end">
            <div className="btn-group" role="group">
              <button type="button" className="btn btn-danger" onClick={handleDecrement}>-</button>
              <button type="button" className="btn btn-light" disabled>{counter}</button>
              <button type="button" className="btn btn-success" onClick={handleIncrement}>+</button>
            </div>
          </div>
        </div>
        <div>
          
        </div>
        
        {counter > 0 && selectButtonVisible && (
          <div className=" mt-3 d-grid">
          <button className="btn btn-primary mt-3" onClick={handleSelect}>
            Select {counter} {counter === 1 ? 'Room' : 'Rooms'}
          </button>
          <span className='text-danger' style={{fontSize: "15px"}}>(Pleace click the select button)</span>
          <span className='text-success' style={{fontSize: "15px"}}>
              You selected {counter} {counter === 1 ? 'Room' : 'Rooms'} - maximum {roomCapacity * counter} people allowed </span>

          </div>
        )}
    
      </div>
    </div>
  );
};

export default RoomCard;
