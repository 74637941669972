import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper/modules';

const RoomImages = ({ images }) => {
  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={1}
      modules={[Navigation]}
      pagination={{ clickable: true }}
      className=""
      navigation={true}
    >
      {images.map((imgSrc, index) => (
        <SwiperSlide key={index} style={{ height: "350px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img src={imgSrc} alt={`Slide ${index}`} className='img-fluid' style={{objectFit:"cover"}}  />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default RoomImages;
