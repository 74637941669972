import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./component/layout";
import Home from "./pages/home";
import About from "./pages/about";
import Rooms from "./pages/rooms";
import Booking from './pages/booking';
import Contact from "./pages/contact";
import PaymentSuccess from "./pages/paymentSuccess";
import Hotels from "./pages/hotels";





const router = createBrowserRouter([
  
  {
    path: "/",
    Component: Layout,
    children: [
      {
        index: true,
        element: <Home/>
      },
      {
        path: "/about",
        element: <About/>
      },
      {
        path: "/hotels",
        element: <Hotels/>
      },
      {
        path: "/:slug",
        element: <Rooms/>
      },
      {
        path: "/booking/:slug",
        element: <Booking/>
      },
      {
        path: "/contact",
        element: <Contact/>
      },
      {
        path: "/payment-success/:slug",
        element: <PaymentSuccess/>
      },
    ],
  },
]);

function Router() {
  return <RouterProvider router={router} />;
}

export default Router;
