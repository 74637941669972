import axios from './axios.interceptor';
import api from './api.util';

export const checkAvabUrl = `${api}/availability` 
export function checkavAilability( obj ) {
    const asd = axios.post(checkAvabUrl, obj, {
      cors: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type"
      }
    });
    return asd;
}

