
import React from "react";
import { Outlet } from "react-router-dom";
import Headers from "../templates/Header";
import Footer from "../templates/footer";

function Layout({ children }) {

    return(
        <>
        <Headers/>
        {/* <h1>header</h1> */}
        <Outlet />
        {/* <p>Footer</p> */}
        <Footer />
        </>
    )
}
export default Layout;
