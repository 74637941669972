import React from "react";
import { useLocation } from "react-router-dom";
import { generateInvoice, singlePaymentDetails } from "../../crud/payment.crud";
import { useEffect, useState } from "react";

export default function PaymentSuccess() {
    const location = useLocation();
    const pathSegments = location.pathname.split("/").filter(segment => segment !== "");
    const paymentId = pathSegments.length > 0 ? pathSegments[pathSegments.length - 1] : null;
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [bookingDetails, setBookingDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const formatDate = (dateString) => {
        const dateObj = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return dateObj.toLocaleDateString('en-US', options);
    }

    const formatCurrency = (amount, currency) => {
        return `${amount} ${currency}`;
    }

    useEffect(() => {
        if (paymentId) {
            singlePaymentDetails(paymentId)
                .then(res => {
                    console.log("singlePaymentDetails", res.data);
                    setBookingDetails(res.data.booking);
                    setPaymentDetails(res.data.payment);
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Error fetching payment details:", error);
                    setError("Failed to load payment details. Please try again later.");
                    setLoading(false);
                });
        }
    }, [paymentId]);

    // const handleDownloadInvoice = () => {
    //     // Send a request to download the invoice
    //     generateInvoice(paymentId)
    //         .then(response => {
    //             if (!response.ok) {
    //                 throw new Error('Failed to download invoice');
    //             }
    //             return response.blob();
    //         })
    //         .then(blob => {
    //             // Create a URL for the blob object
    //             const url = window.URL.createObjectURL(new Blob([blob]));

    //             // Create an anchor element to trigger the download
    //             const a = document.createElement('a');
    //             a.href = url;
    //             a.download = `invoice_${paymentDetails.id}.pdf`; // Set the filename
    //             document.body.appendChild(a);
    //             a.click();

    //             // Cleanup
    //             window.URL.revokeObjectURL(url);
    //             document.body.removeChild(a);
    //         })
    //         .catch(error => {
    //             console.error('Error downloading invoice:', error);
    //             // Handle error (e.g., show error message to the user)
    //         });
    // }

    return (
        <div className="container mt-5">
            <div className="row justify-content-center mb-5">
                <div className="col-md-8">
                    <div className="card" style={{borderRadius:"none"}}>
                        <div className="card-header bg-success text-white">
                            <h4 className="mb-0">Payment Success</h4>
                        </div>
                        <div className="card-body">
                            {loading && <p>Loading payment details...</p>}
                            {error && <p className="text-danger">{error}</p>}
                            {paymentDetails && (
                                <div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <p><strong>Payment ID:</strong> {paymentDetails.id}</p>
                                            <p><strong>Date:</strong> {formatDate(paymentDetails.created_at)}</p>
                                        </div>
                                        <div className="col-sm-6 text-right">
                                            <p><strong>Phone:</strong> {paymentDetails.contact}</p>
                                            <p><strong>Email:</strong> {paymentDetails.email}</p>
                                            <p><strong>Pyment Method:</strong> {paymentDetails.method}</p>
                                        </div>
                                    </div>
                                    <div className="table-responsive mt-4">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Description</th>
                                                    <th>Quantity</th>
                                                    <th>Price</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{paymentDetails.description}</td>
                                                    <td>1</td>
                                                    <td>{formatCurrency(paymentDetails.amount / 100, paymentDetails.currency)}</td>
                                                    <td>{formatCurrency(paymentDetails.amount / 100, paymentDetails.currency)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row justify-content-end mt-4">
                                        <div className="col-sm-4">
                                            <p><strong>Subtotal:</strong> {formatCurrency(paymentDetails.amount / 100, paymentDetails.currency)}</p>
                                            <p><strong>Tax:</strong> {formatCurrency(paymentDetails.tax / 100, paymentDetails.currency)}</p>
                                            <h5 className="mb-0"><strong>Total:</strong> {formatCurrency((paymentDetails.amount + paymentDetails.tax) / 100, paymentDetails.currency)}</h5>
                                        </div>
                                    </div>
                                    {bookingDetails && (
                                        <div className="mt-4">
                                            <h5>Booking Details</h5>
                                            <div className="table-responsive">
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Hotel Name</th>
                                                            <th>Client Name</th>
                                                            <th>Booking From</th>
                                                            <th>Booking To</th>
                                                            <th>Room Type</th>
                                                            <th>Total Rooms</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {bookingDetails.map((booking, index) => (
                                                            <tr key={index}>
                                                                <td>{booking.hotel_slug}</td>
                                                                <td>{booking.clientName}</td>
                                                                <td>{formatDate(booking.bookingFrom)}</td>
                                                                <td>{formatDate(booking.bookingUpto)}</td>
                                                                <td>{booking.roomType}</td>
                                                                <td>{booking.totalRooms}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                    {/* <button className="btn btn-primary mt-4" onClick={handleDownloadInvoice}>
                                        Download Invoice
                                    </button> */}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
