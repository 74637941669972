import React from 'react';

const ProgressBar = ({ step }) => {
    const getProgressWidth = (step) => {
        return (step - 1) * 25; // Adjusting for step 1 to be 0%
    };

    const getStepLabel = (currentStep, targetStep) => {
        return currentStep > targetStep ? '✔️' : `Step ${targetStep}`;
    };

    return (
        <div className="progress mb-3" style={{ height: '30px', position: 'relative', backgroundColor: '#e9ecef' }}>
            <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${getProgressWidth(step)}%`, backgroundColor: '#feaf39' }}
                aria-valuenow={getProgressWidth(step)}
                aria-valuemin="0"
                aria-valuemax="100"
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', top: '0', left: '0', right: '0', height: '100%', color: 'black', fontWeight: 'bold' }}>
                <div style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {getStepLabel(step, 1)}
                </div>
                <div style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {getStepLabel(step, 2)}
                </div>
                <div style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {getStepLabel(step, 3)}
                </div>
                <div style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {getStepLabel(step, 4)}
                </div>
            </div>
        </div>
    );
};

export default ProgressBar;
