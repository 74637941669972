import React from "react";

import Booking from "../../component/hotel/booking";


export default function Bookings(){
return(
    <>
    <Booking />
    </>
)
}