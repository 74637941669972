import React from "react";
import Banner from "../../component/homepage/Banner";
import HomeBooking from "../../component/homepage/HomeBooking";
import About from "../../component/about/About";
import Info from "../../component/homepage/Info";
import Services from "../../component/homepage/Services";

export default function Home(){
return(
    <>
    <Banner />
    <HomeBooking />
    <About />
    <Info/>
    <Services/>
    </>
)
}